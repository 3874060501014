import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Progress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import _classes from "../styles/main.module.css";

const useStyles = makeStyles(() => ({
  contactForm: {
    backgroundColor: "#fff",
    margin: "0 auto",
    padding: 30,
    borderRadius: 4,
    boxShadow: "0 2px 8px 0 rgba(60, 64, 67, .2)",
  },
  fieldGroup: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 -10px",
  },
  fieldBox: {
    flex: "1 1 100%",
    padding: 10,
  },
  field: {
    display: "block",
    width: "100%",
    padding: 10,
    outline: 0,
    borderRadius: 4,
    border: "1px solid #ddd",
    background: "#f9f9f9",
    fontSize: 14,
  },
  inputText: {
    height: 48,
  },
  textarea: {
    resize: "none",
    fontFamily: "inherit",
  },
  col2: {
    flex: "1 1 50%",
  },
}));

export default function contactForm2() {
  const classes = useStyles();

  const [status, setstatus] = useState("");

  function sendRequest(event) {
    event.preventDefault();
    if (status === "progress") return;

    const formData = new FormData(event.target);
    //window.posthog.people.set({email: formData.get('email')});
    const payload = {};
    payload.page = window.location.href;
    for (const key of formData.keys()) {
      payload[key] = formData.get(key);
    }

    setstatus("progress");
    fetch("https://hooks.zapier.com/hooks/catch/4636052/o5fz1hb/", {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject(new Error("An error occured!"))
      )
      .then(() => {
        window.localStorage.setItem("email", formData.get("email"));
        document.write();
        window.location.href = "/thank-you/";
      })
      .catch(() => {
        setstatus("error");
      });
  }

  return (
    <div className={classes.root} id="contact">
      <form className={classes.contactForm} onSubmit={sendRequest}>
        <p style={{ fontSize: 24, fontWeight: 600, margin: "0 0 20px" }}>
          Get a personalized demo
        </p>
        <div className={classes.fieldGroup}>
          <div className={classes.fieldBox}>
            <input
              type="text"
              name="name"
              className={`${classes.field} ${classes.inputText}`}
              placeholder="Your Name"
              required
            />
          </div>

          <div className={classes.fieldBox}>
            <input
              type="email"
              name="email"
              id="email"
              className={`${classes.field} ${classes.inputText}`}
              placeholder="Work Email"
              required
            />
          </div>

          <div className={classes.fieldBox}>
            <input
              type="text"
              name="company"
              className={`${classes.field} ${classes.inputText}`}
              placeholder="Company Name"
              required
            />
          </div>
          {/* <div className={`${styles.fieldBox} ${styles.col2}`}>
            <select name="company-size" className="inputText" required>
              <option value="">Company Size</option>
              <option value="1-10">1-10</option>
              <option value="11-50">11-50</option>
              <option value="51-200">51-200</option>
              <option value="201-1000">201-1000</option>
              <option value="1000-5000">1000-5000</option>
              <option value="5000+">5000+</option>
            </select>
          </div>
          <div className={`${styles.fieldBox} ${styles.col2}`}>
            <select name="job-title" className="inputText" required>
              <option value="">Job Title</option>
              <option value="Owner">Owner</option>
              <option value="C-Level">C-Level</option>
              <option value="Vice-President">Vice-President</option>
              <option value="Director">Director</option>
              <option value="Manager">Manager</option>
              <option value="Specialist">Specialist</option>
              <option value="Others">Others</option>
            </select>
          </div> */}

          <div className={classes.fieldBox}>
            <textarea
              className={`${classes.field} ${classes.textarea}`}
              name="message"
              placeholder="How can we help you?"
              rows="5"
              minLength="50"
              // onInvalid={(event) => event.target.setCustomValidity('Please enter atleast 50 characters to better describe your requirements.')}
              // onInput={(event) => event.target.setCustomValidity(' ')}
              required
            />
          </div>

          <div className={classes.fieldBox}>
            <button
              type="submit"
              className={_classes.button}
              style={{ width: "100%", fontWeight: 600 }}
            >
              {status === "progress" ? (
                <Progress color="#fff" size={25} />
              ) : (
                "Send Request"
              )}
            </button>
            {status === "error" && (
              <div style={{ marginTop: 24 }}>
                <Alert severity="error">
                  An error occured while sending request.
                </Alert>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
