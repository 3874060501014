import React from "react";
import Container from "@material-ui/core/Container";
import ContactForm from "../components/contact-form-2";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Spacer from "../components/spacer";
import { Grid } from "@material-ui/core";

export default function GetDemo() {
  return (
    <Layout>
      <SEO title="Request demo" description="Request demo" />

      <Spacer h={50} />

      <Container>
        <Grid container>
          <Grid xs={12} sm={8} md={6}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>

      <div style={{ marginTop: 160 }} />
    </Layout>
  );
}
